import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '../Button';

const BackLink = ({ link, linkLabel, backFunction }) => {
  if (!link) {
    return null;
  }

  const BackLinkWrapper = backFunction ? Button : Link;

  return (
    <div className="BackLink">
      <BackLinkWrapper
        className={classNames({
          BackLink__ButtonLabel: backFunction,
          BackLink__LinkLabel: !backFunction,
        })}
        {...(backFunction ? { onClick: backFunction } : { to: link })}
      >
        {linkLabel}
      </BackLinkWrapper>
    </div>
  );
};

BackLink.defaultProps = {
  link: '',
  linkLabel: 'View All Reports',
  backFunction: undefined,
};

BackLink.propTypes = {
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  backFunction: PropTypes.func,
};

export default BackLink;
