import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useAuth } from '../../../hooks/auth';
import PageTopper from '../PageTopper';
import NonAuthWrapper from './NonAuthWrapper.component';
import BackLink from './BackLink.component';
import Title from './Title.component';

import './Header.scss';

const Header = ({
  title,
  subTitle,
  pretitle,
  backLink,
  backLinkLabel,
  backFunction,
  titleFirst,
}) => {
  const { isAuthenticated } = useAuth();
  const Wrapper = isAuthenticated() ? PageTopper : NonAuthWrapper;

  return (
    <Wrapper>
      <div
        className={classNames('Header', {
          'Header--titleFirst': titleFirst,
        })}
      >
        <Title title={title} subTitle={subTitle} pretitle={pretitle} />
        <BackLink
          link={backLink}
          linkLabel={backLinkLabel}
          backFunction={backFunction}
        />
      </div>
    </Wrapper>
  );
};

Header.defaultProps = {
  subTitle: null,
  backLink: '',
  backLinkLabel: '',
  backFunction: undefined,
  titleFirst: false,
  pretitle: '',
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  backLink: PropTypes.string,
  backLinkLabel: PropTypes.string,
  backFunction: PropTypes.func,
  titleFirst: PropTypes.bool,
  pretitle: PropTypes.string,
};

export default Header;
